// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from 'firebase'
import { connect } from 'react-firebase'

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock'
import AlertBlock from '../../blocks/alert/src/AlertBlock.web'
import AutomaticCheckoutCalculation from "../../blocks/automaticcheckoutcalculation/src/AutomaticCheckoutCalculation";
import Integrationwithwhatsapp2 from "../../blocks/integrationwithwhatsapp2/src/Integrationwithwhatsapp2";
import Integrationwithshiprocket4 from "../../blocks/integrationwithshiprocket4/src/Integrationwithshiprocket4";
import Hamburgermenu from "../../blocks/hamburgermenu/src/Hamburgermenu";
import Splashscreen from "../../blocks/splashscreen/src/Splashscreen";
import OrderManagement from "../../blocks/ordermanagement/src/OrderManagement";
import ShoppingCartOrders from "../../blocks/shoppingcart/src/ShoppingCartOrders";
import AddShoppingCartOrderItem from "../../blocks/shoppingcart/src/AddShoppingCartOrderItem";
import Filteritems from "../../blocks/filteritems/src/Filteritems";
import Filteroptions from "../../blocks/filteritems/src/Filteroptions";
import Onboardingguide from "../../blocks/onboardingguide/src/Onboardingguide";
import PhoneNumberInput from "../../blocks/mobile-account-registration/src/PhoneNumberInput";
import AdditionalDetailForm from "../../blocks/mobile-account-registration/src/AdditionalDetailForm";
import Emailnotifications2 from "../../blocks/emailnotifications2/src/Emailnotifications2";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth";
import Contentmanagement3 from "../../blocks/contentmanagement3/src/Contentmanagement3";
import Sharewishlistwithotheruser3 from "../../blocks/sharewishlistwithotheruser3/src/Sharewishlistwithotheruser3";
import VisualAnalytics from "../../blocks/visualanalytics/src/VisualAnalytics";
import RecommendationEngine from "../../blocks/recommendationengine/src/RecommendationEngine";
import Itemavailability from "../../blocks/itemavailability/src/Itemavailability";
import Adminconsole2 from "../../blocks/adminconsole2/src/Adminconsole2";
import Notifications from "../../blocks/notifications/src/Notifications";
import Location from "../../blocks/location/src/Location";
import Splitpayments2 from "../../blocks/splitpayments2/src/Splitpayments2";
import Affiliateurl from "../../blocks/affiliateurl/src/Affiliateurl";
import Imagethumbnail from "../../blocks/imagethumbnail/src/Imagethumbnail";
import Paymentadmin2 from "../../blocks/paymentadmin2/src/Paymentadmin2";
import Refundmanagement from "../../blocks/refundmanagement/src/Refundmanagement";
import Blogpostsmanagement from "../../blocks/blogpostsmanagement/src/Blogpostsmanagement";
import ApiIntegration from "../../blocks/apiintegration/src/ApiIntegration";
import Realtimeupdates2 from "../../blocks/realtimeupdates2/src/Realtimeupdates2";
import CountryCodeSelector from "../../blocks/country-code-selector/src/CountryCodeSelector";
import Catalogue from "../../blocks/catalogue/src/Catalogue";
import Splitviewinterface2 from "../../blocks/splitviewinterface2/src/Splitviewinterface2";
import Pushnotifications from "../../blocks/pushnotifications/src/Pushnotifications";
import AccountGroups from "../../blocks/accountgroups/src/AccountGroups";
import Contactus from "../../blocks/contactus/src/Contactus";
import AddContactus from "../../blocks/contactus/src/AddContactus";
import Emojis from "../../blocks/emojis/src/Emojis";
import Settings2 from "../../blocks/settings2/src/Settings2";
import Share from "../../blocks/share/src/Share";
import Rolesandpermissions from "../../blocks/rolesandpermissions/src/Rolesandpermissions";
import Contactsintegration from "../../blocks/contactsintegration/src/Contactsintegration";
import Sorting from "../../blocks/sorting/src/Sorting";
import PostCreation from "../../blocks/postcreation/src/PostCreation";
import Posts from "../../blocks/postcreation/src/Posts";
import PostDetails from "../../blocks/postcreation/src/PostDetails";
import SocialMediaAccountLoginScreen from "../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen";
import Wishlist2 from "../../blocks/wishlist2/src/Wishlist2";
import Reviews from "../../blocks/reviews/src/Reviews";
import AddReview from "../../blocks/reviews/src/AddReview";
import SocialMediaAccountRegistrationScreen from "../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen";
import ForgotPassword from "../../blocks/forgot-password/src/ForgotPassword";
import ForgotPasswordOTP from "../../blocks/forgot-password/src/ForgotPasswordOTP";
import NewPassword from "../../blocks/forgot-password/src/NewPassword";
import Chat from "../../blocks/chat/src/Chat";
import ViewChat from "../../blocks/chat/src/ViewChat";
import Couponcodegenerator from "../../blocks/couponcodegenerator/src/Couponcodegenerator";
import StripePayments from "../../blocks/stripepayments/src/StripePayments";
import ImportExportData from "../../blocks/importexportdata/src/ImportExportData";
import Invitefriends from "../../blocks/invitefriends/src/Invitefriends";
import Deeplinking from "../../blocks/deeplinking/src/Deeplinking";
import Cataloguecreationform3 from "../../blocks/cataloguecreationform3/src/Cataloguecreationform3";
import InvoiceBilling from "../../blocks/invoicebilling/src/InvoiceBilling";
import BulkUploading from "../../blocks/bulkuploading/src/BulkUploading";
import CarouselDisplay from "../../blocks/carouseldisplay/src/CarouselDisplay";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration";
import Appleidsignin2 from "../../blocks/appleidsignin2/src/Appleidsignin2";
import PhotoLibrary from "../../blocks/photolibrary/src/PhotoLibrary";
import Affiliateform3 from "../../blocks/affiliateform3/src/Affiliateform3";
import Loyaltysystem from "../../blocks/loyaltysystem/src/Loyaltysystem";
import Reorderandreturnofitem3 from "../../blocks/reorderandreturnofitem3/src/Reorderandreturnofitem3";
import Categoriessubcategories from "../../blocks/categoriessubcategories/src/Categoriessubcategories";
import Trending from "../../blocks/trending/src/Trending";
import ReviewApprovalAdmin from "../../blocks/reviewandapproval/src/ReviewApprovalAdmin";
import ReviewApprovalBasicUser from "../../blocks/reviewandapproval/src/ReviewApprovalBasicUser";



const routeMap = {
AutomaticCheckoutCalculation:{
 component:AutomaticCheckoutCalculation,
path:"/AutomaticCheckoutCalculation"},
Integrationwithwhatsapp2:{
 component:Integrationwithwhatsapp2,
path:"/Integrationwithwhatsapp2"},
Integrationwithshiprocket4:{
 component:Integrationwithshiprocket4,
path:"/Integrationwithshiprocket4"},
Hamburgermenu:{
 component:Hamburgermenu,
path:"/Hamburgermenu"},
Splashscreen:{
 component:Splashscreen,
path:"/Splashscreen"},
OrderManagement:{
 component:OrderManagement,
path:"/OrderManagement"},
ShoppingCartOrders:{
 component:ShoppingCartOrders,
path:"/ShoppingCartOrders"},
AddShoppingCartOrderItem:{
 component:AddShoppingCartOrderItem,
path:"/AddShoppingCartOrderItem"},
Filteritems:{
 component:Filteritems,
path:"/Filteritems"},
Filteroptions:{
 component:Filteroptions,
path:"/Filteroptions"},
Onboardingguide:{
 component:Onboardingguide,
path:"/Onboardingguide"},
PhoneNumberInput:{
 component:PhoneNumberInput,
path:"/PhoneNumberInput"},
AdditionalDetailForm:{
 component:AdditionalDetailForm,
path:"/AdditionalDetailForm"},
Emailnotifications2:{
 component:Emailnotifications2,
path:"/Emailnotifications2"},
OTPInputAuth:{
 component:OTPInputAuth,
path:"/OTPInputAuth"},
Contentmanagement3:{
 component:Contentmanagement3,
path:"/Contentmanagement3"},
Sharewishlistwithotheruser3:{
 component:Sharewishlistwithotheruser3,
path:"/Sharewishlistwithotheruser3"},
VisualAnalytics:{
 component:VisualAnalytics,
path:"/VisualAnalytics"},
RecommendationEngine:{
 component:RecommendationEngine,
path:"/RecommendationEngine"},
Itemavailability:{
 component:Itemavailability,
path:"/Itemavailability"},
Adminconsole2:{
 component:Adminconsole2,
path:"/Adminconsole2"},
Notifications:{
 component:Notifications,
path:"/Notifications"},
Location:{
 component:Location,
path:"/Location"},
Splitpayments2:{
 component:Splitpayments2,
path:"/Splitpayments2"},
Affiliateurl:{
 component:Affiliateurl,
path:"/Affiliateurl"},
Imagethumbnail:{
 component:Imagethumbnail,
path:"/Imagethumbnail"},
Paymentadmin2:{
 component:Paymentadmin2,
path:"/Paymentadmin2"},
Refundmanagement:{
 component:Refundmanagement,
path:"/Refundmanagement"},
Blogpostsmanagement:{
 component:Blogpostsmanagement,
path:"/Blogpostsmanagement"},
ApiIntegration:{
 component:ApiIntegration,
path:"/ApiIntegration"},
Realtimeupdates2:{
 component:Realtimeupdates2,
path:"/Realtimeupdates2"},
CountryCodeSelector:{
 component:CountryCodeSelector,
path:"/CountryCodeSelector"},
Catalogue:{
 component:Catalogue,
path:"/Catalogue"},
Splitviewinterface2:{
 component:Splitviewinterface2,
path:"/Splitviewinterface2"},
Pushnotifications:{
 component:Pushnotifications,
path:"/Pushnotifications"},
AccountGroups:{
 component:AccountGroups,
path:"/AccountGroups"},
Contactus:{
 component:Contactus,
path:"/Contactus"},
AddContactus:{
 component:AddContactus,
path:"/AddContactus"},
Emojis:{
 component:Emojis,
path:"/Emojis"},
Settings2:{
 component:Settings2,
path:"/Settings2"},
Share:{
 component:Share,
path:"/Share"},
Rolesandpermissions:{
 component:Rolesandpermissions,
path:"/Rolesandpermissions"},
Contactsintegration:{
 component:Contactsintegration,
path:"/Contactsintegration"},
Sorting:{
 component:Sorting,
path:"/Sorting"},
PostCreation:{
 component:PostCreation,
path:"/PostCreation"},
Posts:{
 component:Posts,
path:"/Posts"},
PostDetails:{
 component:PostDetails,
path:"/PostDetails"},
SocialMediaAccountLoginScreen:{
 component:SocialMediaAccountLoginScreen,
path:"/SocialMediaAccountLoginScreen"},
Wishlist2:{
 component:Wishlist2,
path:"/Wishlist2"},
Reviews:{
 component:Reviews,
path:"/Reviews"},
AddReview:{
 component:AddReview,
path:"/AddReview"},
SocialMediaAccountRegistrationScreen:{
 component:SocialMediaAccountRegistrationScreen,
path:"/SocialMediaAccountRegistrationScreen"},
ForgotPassword:{
 component:ForgotPassword,
path:"/ForgotPassword"},
ForgotPasswordOTP:{
 component:ForgotPasswordOTP,
path:"/ForgotPasswordOTP"},
NewPassword:{
 component:NewPassword,
path:"/NewPassword"},
Chat:{
 component:Chat,
path:"/Chat"},
ViewChat:{
 component:ViewChat,
path:"/ViewChat"},
GroupVideoCall:{
 component:GroupVideoCall,
path:"/GroupVideoCall"},
Couponcodegenerator:{
 component:Couponcodegenerator,
path:"/Couponcodegenerator"},
StripePayments:{
 component:StripePayments,
path:"/StripePayments"},
ImportExportData:{
 component:ImportExportData,
path:"/ImportExportData"},
Invitefriends:{
 component:Invitefriends,
path:"/Invitefriends"},
Deeplinking:{
 component:Deeplinking,
path:"/Deeplinking"},
Cataloguecreationform3:{
 component:Cataloguecreationform3,
path:"/Cataloguecreationform3"},
InvoiceBilling:{
 component:InvoiceBilling,
path:"/InvoiceBilling"},
BulkUploading:{
 component:BulkUploading,
path:"/BulkUploading"},
CarouselDisplay:{
 component:CarouselDisplay,
path:"/CarouselDisplay"},
MobileAccountLoginBlock:{
 component:MobileAccountLoginBlock,
path:"/MobileAccountLoginBlock"},
EmailAccountRegistration:{
 component:EmailAccountRegistration,
path:"/EmailAccountRegistration"},
Appleidsignin2:{
 component:Appleidsignin2,
path:"/Appleidsignin2"},
PhotoLibrary:{
 component:PhotoLibrary,
path:"/PhotoLibrary"},
Affiliateform3:{
 component:Affiliateform3,
path:"/Affiliateform3"},
Loyaltysystem:{
 component:Loyaltysystem,
path:"/Loyaltysystem"},
Reorderandreturnofitem3:{
 component:Reorderandreturnofitem3,
path:"/Reorderandreturnofitem3"},
Categoriessubcategories:{
 component:Categoriessubcategories,
path:"/Categoriessubcategories"},
Trending:{
 component:Trending,
path:"/Trending"},
ReviewApprovalAdmin:{
 component:ReviewApprovalAdmin,
path:"/ReviewApprovalAdmin"},
ReviewApprovalBasicUser:{
 component:ReviewApprovalBasicUser,
path:"/ReviewApprovalBasicUser"},

  Home: {
component:VisualAnalytics,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  }

};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    
    return (
      <View style={{ height: '100vh', width: '100vw' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
